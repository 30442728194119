
import { defineComponent, ref, computed, reactive, onMounted } from 'vue'
import sectionLayoutContent from '@/components/common/section-layout-content.vue'
import MappingMultiProductVariant from './MappingMultiProductVariant.vue'
import userProductRepositories from '@/repositories/userProductRepositories'
import userCatalogRepositories from '@/repositories/userCatalogRepositories'
import { useRoute, useRouter } from 'vue-router'

export default defineComponent({
  components: {
    sectionLayoutContent,
    MappingMultiProductVariant,
  },
  setup(props) {
    const breadcrumbs = [
      {
        text: `Products`,
        link: 's.products',
        params: null,
      },
      {
        text: `Mapping multiple`,
        link: '',
        params: null,
      },
    ]

    const content = computed(() => {
      return { breadcrumbs }
    })

    const state = reactive({
      options: ref([
        {
          value: 'option',
          label: 'Mapping with option',
        },
        {
          value: 'variant',
          label: 'Mapping with variant',
        },
      ]),
      value: 'option',
      catalogTypeNumber: 1,
      products: ref<any[]>([]),
      catalogs: ref<any[]>([]),
      productIds: ref<any[]>([]),
      isLoading: true,
    })

    const route = useRoute()
    const router = useRouter()
    const info = JSON.parse(localStorage.getItem('info') || '')
    const { getAll } = userProductRepositories()
    const { getCatalogs } = userCatalogRepositories()

    const loadData = async (ids: string) => {
      state.isLoading = true
      const params = { ids: ids }
      let { status, data } = await getAll(params)
      if (status === 200 || status === 201) {
        state.products = data as any[]
      }
      let res = await getCatalogs({
        page: 1,
        limit: 10000,
        basecost: info?.sellerTeam?.basecost?.id,
      })
      state.catalogs = res as any[]
      state.isLoading = false
    }
    const ids = route?.params?.id as string

    console.log('pid', ids)
    let pid = ids.split(',')
    state.productIds = pid
    loadData(ids)

    return {
      content,
      state,
    }
  },
})
